<template>
  <BTableCard
    :searchActive="true"
    :newAddActive="false"
    :totalActive="true"
    :backActive="backTo"
    :show="show"
    :icon="'fad fa-files-medical'"
    :title="'Oteller'"
    :total="total"
    :filtered="filtered"
  >
    <template v-slot:headerAction>
      <div>
        <b-button
          squared
          variant="warning"
          class="icon btn-icon mr-1"
          v-if="!backTo"
          @click="filtered = !filtered"
          v-b-tooltip.hover="'Filtrele'"
        >
          <i class="fad fa-filter" />
        </b-button>
        <b-button squared variant="success" @click="handlerAddNew" v-b-tooltip.hover="'Yeni Kayıt'">
          <i class="fad fa-plus" /> YENİ KAYIT
        </b-button>
        <b-button squared variant="secondary" @click="handlerOpenApiModal" v-b-tooltip.hover="'Api ile veri çek'">
          <i class="fad fa-tape" /> API İLE VERİ ÇEK
        </b-button>
      </div>
    </template>
    <template v-slot:body>
      <div class="p-1">
        <transition mode="out-in">
          <router-view
            @total="total = $event"
            @show="show = $event"
            @backTo="backTo = $event"
            :newAdd="newAdd"
            :filtered="filtered"
            @filtered="handlerFiltered($event)"
            @closeEvent="newAdd = $event"
            :openApiModal="openApiModal"
            @closeApiModal="openApiModal = $event"
          />
        </transition>
      </div>
    </template>
  </BTableCard>
</template>
<script>
import { defineComponent, ref, onMounted } from '@vue/composition-api';
import BTableCard from '@/components/cards/BTableCard.vue';
import { useRouter } from '@/libs/utils';
import store from '@/store';
export default defineComponent({
  components: {
    BTableCard,
  },
  setup() {
    const expo = {};
    const { route, router } = useRouter();
    expo.show = ref(false);
    expo.backTo = ref(false);
    expo.total = ref(0);
    expo.newAdd = ref(false);
    expo.openApiModal = ref(false);
    expo.filtered = ref(false);

    expo.handlerAddNew = () => {
      expo.newAdd.value = true;
    };

    expo.handlerOpenApiModal = () => {
      expo.openApiModal.value = true;
    };

    expo.handlerFiltered = (event) => {
      expo.filtered.value = event;
    };

    onMounted(async () => {
      expo.show.value = true;
      await handlerFetchAllData();
      expo.backTo.value = false;
    });

    const handlerFetchAllData = async () => {
      const statu = { statu: true };
      await store.dispatch('bolgeListele', statu);
    };

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped>
table.vgt-table {
  font-size: 14px;
  thead {
    background-color: rgba(#000, 0.07);
  }
  td {
    padding: 3px 0px 0px 5px !important;
    vertical-align: middle !important;
  }
}
</style>
